import React, { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { RequestMethod, orbitRequest } from '../utils/api.utils';
import { toast } from 'react-toastify';
import { CompanyType } from '../types/company.types';
import { defaultSubscriptionTemplate } from '../app/modules/add-edit-subscription/utils';
import { FieldType } from '../utils/field.utils';
import { CarModel, SubscriptionsContextType } from '../types/subscription.types';

const SubscriptionsContext = createContext<SubscriptionsContextType>(null);

function SubscriptionsProvider(props: PropsWithChildren<unknown>) {
  const [searchInput, setSearchInput] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDays, setSelectedDays] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState<{id: number, name: string}[]>([]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState([]);
  const [subscriptionCompanies, setSubscriptionCompanies] = useState<CompanyType[]>([]);
  const [importRules, setImportRules] = useState<string>('');
  const [carModels, setCarModels] = useState<CarModel[]>();
  const [subscriptionFields, setSubscriptionFields] = useState<FieldType[]>(
    defaultSubscriptionTemplate.fields.subscription
  );
  const [clientFields, setClientFields] = useState<FieldType[]>(
    defaultSubscriptionTemplate.fields.client
  );
  const [vehicleFields, setVehicleFields] = useState<FieldType[]>(
    defaultSubscriptionTemplate.fields.vehicle
  );

  const getCarModels = async (type: string) => {
    const response = await orbitRequest({
      route: `/carModels?make_name=${type}`,
      method: RequestMethod.GET,
    });
    if (!response || response.error) {
      return toast.error(`Modelele de masini nu s-au incarcat! ${response?.message || ''}`);
    }
    setCarModels(response.models);
  };

  const mainSubscriptionCompanies = useMemo(
    () => subscriptionCompanies.filter((company) => Boolean(company.main_company_id)),
    [subscriptionCompanies]
  );

  const store: SubscriptionsContextType = {
    subscriptionCompanies,
    setSubscriptionCompanies,
    importRules,
    setImportRules,
    mainSubscriptionCompanies,
    carModels,
    setCarModels,
    getCarModels,
    subscriptionFields,
    setSubscriptionFields,
    clientFields,
    setClientFields,
    vehicleFields,
    setVehicleFields,
    filter: {searchInput, selectedCompanies, selectedTimeInterval, selectedStatus, selectedCampaigns, selectedDays},
    setFilter: {setSearchInput, setSelectedCompanies, setSelectedTimeInterval, setSelectedStatus, setSelectedCampaigns, setSelectedDays}
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <SubscriptionsContext.Provider value={storeForProvider}>
      {props.children}
    </SubscriptionsContext.Provider>
  );
}

export { SubscriptionsContext };
export default SubscriptionsProvider;
