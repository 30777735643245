import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { FlatSubscriptionType } from '../../../../../types/subscription.types';
import DatePicker from 'react-multi-date-picker';
import Select from '../../../../../_metronic/layout/components/select/Select';
import InputField from '../../../../../_metronic/layout/components/fields/input/input-field';
import { cloneSubscription } from '../../../../../setup/axios/subscription.request';
import { PackageContext } from '../../../../../context/packages.context';

const CloneModal = ({
  subscription,
  setModalVisible,
  triggerAdditionalEffect,
}: {
  subscription: FlatSubscriptionType;
  setModalVisible: (_: boolean) => void;
  triggerAdditionalEffect: any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activatedAt, setActivatedAt] = useState<Date>(new Date());
  const [expirationDate, setExpirationDate] = useState<Date>(new Date(subscription.expiration_date));
  const [pricePaid, setPricePaid] = useState<number>(subscription.price_paid);
  const [selectedValue, setSelectedValue] = useState<{ package_id: number; package_name: string } | null>({
    package_id: subscription.package_id,
    package_name: subscription.package_name,
  });
  const [dropdownOptions, setDropdownOptions] = useState<{ id: number; name: string; valability_period: number; valability_unit: 'days' | 'months' | 'years' }[]>([]);
  const [priceError, setPriceError] = useState<string | null>(null);

  const { getPackages } = useContext(PackageContext);

  const fetchSubscriptionPackages = async () => {
    try {
      const result = await getPackages({ id: subscription.company_id });

      if ('packages' in result && Array.isArray(result.packages)) {
        setDropdownOptions(
          result.packages.map((pkg) => ({
            id: pkg.id, // Package ID
            name: pkg.name, // Package Name
            valability_period: pkg.valability_period, // Valability Period
            valability_unit: pkg.valability_unit, // Valability Unit (days/months/years)
          }))
        );
      } else {
        toast.error(result.error || 'Failed to fetch package names.');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while fetching package names.');
    }
  };

  const determineActivatedAt = () => {
    const now = new Date();
    const expiration = new Date(subscription.expiration_date);

    // Calculate the difference in days
    const timeDiff = now.getTime() - expiration.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (expiration < now) {
      if (daysDiff < 0) {
        // If expiration was today or yesterday
        expiration.setHours(0, 0, 0, 0);
        expiration.setDate(expiration.getDate() + 1); // Activate the next day
        setActivatedAt(new Date(expiration));
      } else {
        // If the expiration date has passed by more than 1 day
        const activationDate = new Date(now);
        activationDate.setDate(activationDate.getDate() + 2); // Add 2 days from now
        activationDate.setHours(0, 0, 0, 0);
        setActivatedAt(activationDate);
      }
    } else {
      // If the expiration date is in the future
      expiration.setHours(0, 0, 0, 0);
      expiration.setDate(expiration.getDate() + 1); // Activate the next day
      setActivatedAt(new Date(expiration));
    }
  };

  useEffect(() => {
    fetchSubscriptionPackages();
    determineActivatedAt();
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const selectedPackage = dropdownOptions.find((pkg) => pkg.id === selectedValue.package_id);
      if (selectedPackage) {
        const { valability_period, valability_unit } = selectedPackage;
        const newExpirationDate = calculateExpirationDate(activatedAt, valability_period, valability_unit);
        setExpirationDate(newExpirationDate);
      }
    }
  }, [activatedAt, selectedValue, dropdownOptions]);

  const calculateExpirationDate = (
    date: Date,
    valabilityPeriod: number,
    valabilityUnit: 'days' | 'months' | 'years'
  ): Date => {
    const newDate = new Date(date);

    switch (valabilityUnit) {
      case 'days':
        newDate.setDate(newDate.getDate() + valabilityPeriod);
        break;
      case 'months':
        newDate.setMonth(newDate.getMonth() + valabilityPeriod);
        break;
      case 'years':
        newDate.setFullYear(newDate.getFullYear() + valabilityPeriod);
        break;
      default:
        throw new Error(`Invalid valability unit: ${valabilityUnit}`);
    }
    newDate.setDate(newDate.getDate() - 1);
    // Set the time to the end of the day (23:59:59.999)
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const handleDateChange = (
    dateObject: unknown,
    setDate: React.Dispatch<React.SetStateAction<Date>>
  ) => {
    if (dateObject && typeof dateObject === 'object' && 'toDate' in dateObject) {
      const newDate = (dateObject as any).toDate();
      setDate(newDate);

      // Recalculate expiration date if activatedAt changes
      if (setDate === setActivatedAt && selectedValue) {
        const selectedPackage = dropdownOptions.find((pkg) => pkg.id === selectedValue.package_id);
        if (selectedPackage) {
          const { valability_period, valability_unit } = selectedPackage;
          const newExpirationDate = calculateExpirationDate(newDate, valability_period, valability_unit);
          setExpirationDate(newExpirationDate);
        }
      }
    }
  };

  const handleCloneRequest = async () => {
    try {
      const result = await cloneSubscription(
        subscription.id,
        activatedAt,
        expirationDate,
        pricePaid,
        selectedValue?.package_id
      );

      if (result && !result.error) {
        toast.success('Subscription cloned successfully!');
        triggerAdditionalEffect();
        setModalVisible(false);

        const newSubscriptionId = result.subscription.id;
        window.history.pushState({}, '', `../add-edit-subscription/${newSubscriptionId}`);

        const navigationEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navigationEvent);
      } else {
        toast.error(result?.error || 'Failed to clone the subscription.');
      }
    } catch (error) {
      console.error('Clone error:', error);
      toast.error('An error occurred while cloning the subscription.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (pricePaid === 0 || pricePaid === null) {
      setPriceError('Price Paid cannot be empty or zero.');
      return;
    } else {
      setPriceError(null);
    }
    setIsLoading(true);
    await handleCloneRequest();
  };

  return (
    <div className="py-5 px-8" style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Sigur dorești să clonezi subscripția cu id <b>{subscription.id}</b>?
      </span>
      <span style={{ textAlign: 'center', fontSize: '16px', marginBottom: '0.5rem' }}>
        Beneficiar: <b>{subscription.client_name}</b>
      </span>
      <div className="container" style={{ textAlign: 'center', fontSize: '15px' }}>
        <div style={{ marginBottom: '1.5rem' }}>
          <InputField
            value={pricePaid}
            onChange={(e) => {
              const value = e.target.value;
              setPricePaid(!isNaN(parseFloat(value)) ? parseFloat(value) : 0);
              if (value !== '' && parseFloat(value) > 0) {
                setPriceError(null);
              }
            }}
            field={{ required: true, label: 'Price Paid' }}
            placeholder="Price paid"
            style={{ fontSize: '15px' }}
          />
          {priceError && (
            <span style={{ color: 'red', fontSize: '0.9rem', display: 'block', marginTop: '0.5rem' }}>
              {priceError}
            </span>
          )}
        </div>

        <div style={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'column' }}>
          <label className="form-label">Package:</label>
          <Select
            id="package-select"
            value={
              dropdownOptions
                .map((pkg) => ({ label: pkg.name, value: pkg.id }))
                .find((option) => selectedValue && option.value === selectedValue.package_id) || null
            }
            options={dropdownOptions.map((pkg) => ({
              label: pkg.name,
              value: pkg.id,
            }))}
            onChange={(option) => {
              if (option) {
                const selectedPackage = dropdownOptions.find((pkg) => pkg.id === option.value);
                if (selectedPackage) {
                  setSelectedValue({
                    package_id: selectedPackage.id,
                    package_name: selectedPackage.name,
                  });

                  // Recalculate expiration date based on the selected package
                  const { valability_period, valability_unit } = selectedPackage;
                  const newExpirationDate = calculateExpirationDate(activatedAt, valability_period, valability_unit);
                  setExpirationDate(newExpirationDate);
                }
              } else {
                setSelectedValue(null);
              }
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 2000 }),
            }}
            placeholder="-- Select a Package --"
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
          <div style={{ flex: '1' }}>
            <label className="form-label">Activation Date:</label>
            <DatePicker
              id="activatedAt"
              value={activatedAt}
              onChange={(dateObject) => handleDateChange(dateObject, setActivatedAt)}
              format="DD-MM-YYYY"
              minDate={new Date()}
              editable={false}
              fixMainPosition
              style={{ height: '100%', width: '100%', fontSize: '15px' }}
            />
          </div>
          <div style={{ flex: '1' }}>
            <label className="form-label">Expiration Date:</label>
            <DatePicker
              id="expirationDate"
              value={expirationDate}
              onChange={(dateObject) => handleDateChange(dateObject, setExpirationDate)}
              format="DD-MM-YYYY"
              editable={false}
              fixMainPosition
              minDate={new Date(activatedAt)}
              style={{ height: '100%', width: '100%', fontSize: '15px' }}
              zIndex={9999}
            />
          </div>
        </div>
      </div>
      <div className="mt-8" style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={isLoading}
          className="btn btn-secondary me-5"
          onClick={() => setModalVisible(false)}
        >
          Cancel
        </button>
        <button disabled={isLoading} className="btn btn-primary" onClick={handleSubmit}>
          Clone
        </button>
      </div>
    </div>
  );
};

export default CloneModal;