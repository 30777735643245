import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext, UserRole } from '../../../context/user.context';
import { LoadingCard } from '../../../_metronic/layout/components/loading/Loading';
import { orbitRequest, RequestMethod } from '../../../utils/api.utils';
import { PageTitle } from '../../../_metronic/layout/core';
import ClientCard from './modules/ClientCard';
import SubscriptionCard from './modules/SubscriptionCard';
import VehicleCard from './modules/VehicleCard';
import CancelledSubscriptionCard from './modules/CancelledSubscriptionCard';
import moment from 'moment';
import { checkSubscriptionsVin, isFormValid } from './utils';
import { SubscriptionsContext } from '../../../context/subscriptions.context';
import { getSubscriptionCompaniesRequest } from '../../../setup/axios/users.request';
import Select from '../../../_metronic/layout/components/select/Select';
import { saveNewSubscription, editSubscription } from '../../../setup/axios/subscription.request';
import {
  SubscriptionType,
  SubscriptionsContextType,
  UserCompanyType,
  emptySubscription,
} from '../../../types/subscription.types';
import { ClientType, emptyClient } from '../../../types/client.types';
import { CompanyType, PackageType } from '../../../types/company.types';
import { VehicleType, emptyVehicle } from '../../../types/vehicle.types';
import { FieldType } from '../../../utils/field.utils';
import { UsersContextType } from '../../../types/user.types';
import { UserModelRole } from '../auth/models/UserModel';
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal';
import InputField from '../../../_metronic/layout/components/fields/input/input-field';

const getSubscriptionForSave = ({
  subscription,
  client,
  vehicle,
}: {
  subscription: SubscriptionType;
  client: ClientType;
  vehicle: VehicleType;
}) => {
  const company_id = subscription?.company_id;
  // 'company_id' => 'required',

  const clientData = JSON.parse(JSON.stringify(client));
  clientData.client_id = client.id;
  delete clientData.id;
  delete clientData.company;
  delete clientData.company_id;

  const vehicleData = JSON.parse(JSON.stringify(vehicle));
  vehicleData.vehicle_id = vehicle.id;
  delete vehicleData.id;
  delete vehicleData.company;
  delete vehicleData.company_id;

  const subscriptionData = {
    id: subscription?.id,
    package_id: subscription.package_id,
    bought_at: subscription.bought_at,
    activated_at: subscription.activated_at,
    expiration_date: subscription.expiration_date,
    status: subscription.status,
    cancelled_status_reason: subscription.cancelled_status_reason,
    payment_type: subscription.subscription_type || subscription.payment_type,
    interface_type: subscription.interface_type,
    newsletter: subscription.newsletter,
    policy_number: subscription.policy_number,
    road_intervention: subscription.road_intervention,
    is_retainer_invoice: subscription.is_retainer_invoice,
    intervention_time: subscription.intervention_time,
    discount: subscription.discount,
    price_paid: subscription.price_paid,
  };

  return {
    company_id,
    ...clientData,
    ...vehicleData,
    ...subscriptionData,
  };
};

const submitSubscription = async ({
  subscription,
  vehicle,
  client,
}: {
  subscription: SubscriptionType;
  vehicle: VehicleType;
  client: ClientType;
}) => {
  if (!subscription || !vehicle || !client) {
    toast.error(
      `There was a problem with your last request. The vehicle, the client, or the subscription itself doesn't exist. Please refresh and try again!`
    );
    return false;
  }
  const payload = getSubscriptionForSave({ subscription, client, vehicle });
  const isEditMode = Boolean(subscription?.id);
  const saveMethod = isEditMode ? editSubscription : saveNewSubscription;
  const response = await saveMethod(payload);

  if (!response || response.error) {
    toast.error(`The subscription request failed with message: ${response?.message}`);
    return false;
  }

  toast.success('Subscription succesfully saved!');
  return true;
};

const isSubscriptionFormValid = async ({
  subscription,
  subscriptionFields,
  client,
  clientFields,
  vehicle,
  vehicleFields,
  warningMessage,
  getField,
}: {
  subscription: SubscriptionType;
  subscriptionFields: FieldType[];
  client: ClientType;
  clientFields: FieldType[];
  vehicle: VehicleType;
  vehicleFields: FieldType[];
  warningMessage: string;
  getField: (section: string, fileName: string, loadedData?: boolean) => string;
}): Promise<boolean> => {
  if (warningMessage) {
    toast.error(
      'Subscripția actuală se suprapune cu o alta! Vă rog verificați mesajul de informare din zona de vehicul și selectați un interval de valabilitate disponibil!'
    );
    return false;
  }
  const clientFormValidResponse = await isFormValid({
    fields: clientFields,
    object: client,
    getField,
  });
  if (clientFormValidResponse.error) {
    toast.error(`In client: ${clientFormValidResponse.errorMessage}`);
    return false;
  }

  const vehicleFormValidResponse = await isFormValid({
    fields: vehicleFields,
    object: vehicle,
    getField,
  });
  if (vehicleFormValidResponse.error) {
    toast.error(`In vehicle: ${vehicleFormValidResponse.errorMessage}`);
    return false;
  }

  const subscriptionFormValidResponse = await isFormValid({
    fields: subscriptionFields,
    object: subscription,
    getField,
  });
  if (subscriptionFormValidResponse.error) {
    toast.error(`In subscription: ${subscriptionFormValidResponse.errorMessage}`);
    return false;
  }

  return true;
};

const CancelledStatusModal = ({
  modalVisible,
  setModalVisible,
  subscription,
  setSubscription,
}: {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  subscription: SubscriptionType;
  setSubscription: (subscription: SubscriptionType) => void;
}) => {
  return (
    <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
      <div
        className='py-5 px-8'
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <p style={{ fontSize: '16px' }}>
          Are you sure you want to set this subscription cancelled?
        </p>
        <p style={{ fontSize: '16px' }}>Complete this field</p>
        <div>
          <label htmlFor="cancelledStatusReason" className="form-label">
            Reason
          </label>
          <textarea
            id="cancelledStatusReason"
            value={subscription.cancelled_status_reason}
            onChange={(ev) => {
              const value = ev.target.value;
              setSubscription({ ...subscription, cancelled_status_reason: value });
            }}
            required
            rows={6}
            style={{ width: '100%', resize: 'vertical', }}
          />
        </div>
        <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='btn btn-secondary me-5'
            onClick={() => {
              setModalVisible(false);
            }}
          >
            Cancel
          </div>
          <div
            className='btn btn-success'
            onClick={() => {
              if (!subscription.cancelled_status_reason) {
                return toast.error('Please add a reason');
              }
              if (subscription.cancelled_status_reason.length < 20) {
                return toast.error('Reason must be at least 20 characters long');
              }
              setSubscription({ ...subscription, status: 'cancelled' });
              setModalVisible(false);
            }}
          >
            Confirm
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

const ButtonsContainer = ({
  isEditMode,
  userCompany,
  vehicle,
  client,
  subscription,
  vehicleFields,
  clientFields,
  subscriptionFields,
  warningMessage,
  getField,
}: {
  isEditMode: boolean;
  userCompany: UserCompanyType;
  vehicle: VehicleType;
  client: ClientType;
  subscription: SubscriptionType;
  vehicleFields: FieldType[];
  clientFields: FieldType[];
  subscriptionFields: FieldType[];
  warningMessage: string;
  getField: (section: string, fileName: string, loadedData?: boolean) => string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isNewAndCantCreate = !isEditMode && !userCompany.companyId;
  const history = useHistory();

  return (
    <div
      className='page__top-buttons'
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'end',
        zIndex: 1000,
        right: '90px',
        top: '12px',
      }}
    >
      <button
        className='btn btn-light w-90px'
        style={{ background: 'none' }}
        onClick={(e) => {
          e.preventDefault();

          history.goBack();
        }}
        type='button'
        disabled={isLoading}
      >
        Cancel
      </button>
      <button
        className='btn btn-primary w-90px'
        style={isNewAndCantCreate ? { opacity: 0.6, boxShadow: 'none' } : {}}
        onClick={async (e) => {
          if (isNewAndCantCreate) {
            return toast.error('Userul nu are companie asignata! Nu se poate crea o subscriptie!');
          }
          setIsLoading(true);
          const checkSubscriptionForm = await isSubscriptionFormValid({
            subscription,
            subscriptionFields,
            client,
            clientFields,
            vehicle,
            vehicleFields,
            getField,
            warningMessage,
          });
          if (checkSubscriptionForm) {
            const subscriptionSaved = await submitSubscription({ subscription, vehicle, client });
            if (subscriptionSaved) {
              setIsLoading(false);
              history.push(`../subscriptions`);
            }
          }

          e.preventDefault();
          setIsLoading(false);
        }}
        disabled={isLoading}
        data-bs-toggle='tooltip'
        data-bs-placement='bottom'
        title={isNewAndCantCreate ? 'Userul nu are companie asignata!' : ''}
        type='submit'
      >
        Save
      </button>
    </div>
  );
};

const SelectCompanyAdmin = ({
  userRole,
  isEditMode,
  userCompany,
  changeUserCompanyEvent,
  companies,
}: {
  userRole: UserRole;
  isEditMode: boolean;
  userCompany: UserCompanyType;
  changeUserCompanyEvent: (company: CompanyType) => void;
  companies: CompanyType[];
}) => {
  if (userRole !== UserRole.Admin && userRole !== UserRole.AdminSubscriptions) {
    return null;
  }
  return (
    <div
      className='card-field pb-7 card-field'
      style={{
        maxWidth: 280,
      }}
    >
      <label className='form-label '>Subscription Company:</label>
      <Select
        options={companies}
        isDisabled={isEditMode}
        getOptionLabel={(company: CompanyType) => company.name}
        getOptionValue={(company: CompanyType) => company.id}
        onChange={changeUserCompanyEvent}
        value={companies.find((company: CompanyType) => company.id === userCompany.companyId)}
      />
    </div>
  );
};

const convertPackageListToDict = (packages: PackageType[]) => {
  const packageDict: { [companyId: number]: PackageType[] } = {};
  packages.forEach((subscriptionPackage: PackageType) => {
    if (!packageDict[subscriptionPackage.company_id]) {
      packageDict[subscriptionPackage.company_id] = [];
    }
    packageDict[subscriptionPackage.company_id].push(subscriptionPackage);
  });
  return packageDict;
};

const AddEditSubscription = ({
  packages,
  loadedSubscription,
  userCompanyInfo,
  userRole,
  companies,
}: {
  packages: PackageType[];
  loadedSubscription: SubscriptionType | null;
  userCompanyInfo: UserCompanyType;
  userRole?: UserRole;
  companies?: CompanyType[];
}) => {
  const { id } = useParams<{ id?: string }>();
  const kiaId = 24;
  const [userCompany, setUserCompany] = useState<UserCompanyType>(userCompanyInfo);
  const [warningMessage, setWarningMessage] = useState<string>();
  const [showCancelledModal, setShowCancelledModal] = useState(false);
  const [vehicle, setVehicle] = useState<VehicleType>({
    ...emptyVehicle,
    company_id: userCompanyInfo.companyId,
    brand: userCompanyInfo.mainCompanyName,
    registration_date: id ? null : '' 
  });
  const [client, setClient] = useState<ClientType>({
    ...emptyClient,
    company_id: userCompanyInfo.companyId,
  });
  const [subscription, setSubscription] = useState<SubscriptionType>({
    ...emptySubscription,
    company_id: userCompanyInfo.companyId,
  });
  const [loaded, setLoaded] = useState<boolean>(!id);
  const { subscriptionFields, clientFields, vehicleFields, setVehicleFields } =
    useContext<SubscriptionsContextType>(SubscriptionsContext);

  useEffect(() => {
    if (userCompanyInfo?.mainCompanyName) {
      updateVehicleTemplateFieldsByCompany({
        mainCompanyName: userCompanyInfo.mainCompanyName,
        setVehicleFields,
        isVinRequired: userCompanyInfo.isVinRequired,
        isRedNumber: vehicle?.red_number,
      });
    }
  }, [Boolean(userCompanyInfo?.mainCompanyName)]);

  const packageDict = useMemo(() => convertPackageListToDict(packages), [packages]);
  const companyPackages = useMemo(
    () => packageDict[userCompany.mainCompanyId || userCompany.companyId],
    [userCompany, packages]
  );

  const changeUserCompanyEvent = (company: CompanyType) => {
    const isMainCompanyKIAOrVOLVO = company?.name === 'KIA' || company?.name === 'VOLVO';
    const isBranch = company?.main_company_name === 'KIA' || company?.main_company_name === 'VOLVO';
    const isCompanyKia = company?.name === 'KIA';

    const isMainCompany = !company.main_company_id;
    const futureUserCompany = {
      companyId: company.id,
      mainCompanyId: company.main_company_id,
      mainCompanyName: isMainCompany ? company.name : company.main_company_name,
      mainCompanyStripePayable: isMainCompany
        ? company.stripe_payable
        : company.main_company_stripe_payable,
      isVinRequired: company?.is_vin_required,
      companyName: company?.name,
    };
    setUserCompany(futureUserCompany);
    setClient({ ...client, company_id: company.id });
    setVehicle({
      ...vehicle,
      company_id: company.id,
      brand: isMainCompanyKIAOrVOLVO
        ? company.name ?? ''
        : isBranch
          ? company.main_company_name ?? ''
          : '',
      model: emptyVehicle.model,
      registration_date: '',
    });
    setSubscription({
      ...subscription,
      company_id: company.id,
      package_id: emptySubscription.package_id,
    });
    updateVehicleTemplateFieldsByCompany({
      mainCompanyName: futureUserCompany.mainCompanyName,
      setVehicleFields,
      isVinRequired: futureUserCompany.isVinRequired,
      isRedNumber: vehicle?.red_number,
    });
  };

  const getField = (section: string, fileName: string, loadedData?: boolean) => {
    switch (true) {
      case section === 'client' && !loadedData:
        return client[fileName];
      case section === 'vehicle' && !loadedData:
        return vehicle[fileName];
      case section === 'subscription' && !loadedData:
        return subscription[fileName];
      default:
        if (!id || !loadedSubscription) {
          return;
        }
        return section !== 'subscription'
          ? loadedSubscription[section][fileName]
          : loadedSubscription[fileName];
    }
  };

  useEffect(() => {
    if (!loaded) {
      if (!loadedSubscription) {
        return;
      }

      setLoaded(true);
      setSubscription(loadedSubscription);
      setClient(loadedSubscription?.client);
      setVehicle(loadedSubscription?.vehicle);
    }
  }, []);

  const checkDataForEdit = () =>
    id
      ? loadedSubscription.vehicle.vin !== vehicle.vin ||
        moment(loadedSubscription.activated_at).format('DD.MM.YYYY') !==
          moment(subscription.activated_at).format('DD.MM.YYYY') ||
        moment(loadedSubscription.expiration_date).format('DD.MM.YYYY') !==
          moment(subscription.expiration_date).format('DD.MM.YYYY')
      : true;

  const checkVin = async () => {
    const response = await checkSubscriptionsVin(
      vehicle.vin,
      subscription.activated_at,
      subscription.expiration_date,
      userCompany.companyId,
      subscription.package_id,
      vehicle.registration_nr
    );
    //if an active subscription is found, the warning message is set with the received message; else warning is null
    setWarningMessage(response?.message || '');
  };

  useEffect(() => {
    if (
      vehicle.vin &&
      subscription.activated_at &&
      subscription.expiration_date &&
      vehicle.registration_nr &&
      checkDataForEdit()
    ) {
      checkVin();
    } else {
      warningMessage && setWarningMessage('');
    }
  }, [
    vehicle.vin,
    subscription.activated_at,
    subscription.expiration_date,
    userCompany?.companyId,
    vehicle.registration_nr,
  ]);

  const precompleteSubsctiptionPackage = async () => {
    const response = await orbitRequest({
      route: `/package/getPackageByDate?registration_date=${moment(vehicle.registration_date).format('DD-MM-YYYY')}&company_id=${userCompany.companyId}`,
      method: RequestMethod.GET,
    });
    if (response?.package) {
      setSubscription({ ...subscription, package_id: response.package.id });
    }
  };

  useEffect(() => {
    if (
      vehicle.registration_date &&
      (userCompany.mainCompanyId === 24 || userCompany.companyId === 24)
    ) {
      const canEdit = id
        ? moment(vehicle.registration_date).format('DD.MM.YYYY') !==
          moment(loadedSubscription.vehicle.registration_date).format('DD.MM.YYYY')
        : true;
      canEdit && precompleteSubsctiptionPackage();
    }
  }, [vehicle.registration_date]);

  if (!loaded) {
    return <LoadingCard />;
  }

  return (
    <div className='needs-validation form'>
      <CancelledStatusModal
        modalVisible={showCancelledModal}
        setModalVisible={setShowCancelledModal}
        subscription={subscription}
        setSubscription={setSubscription}
      />
      <ButtonsContainer
        isEditMode={Boolean(id)}
        warningMessage={warningMessage}
        userCompany={userCompany}
        vehicle={vehicle}
        vehicleFields={vehicleFields}
        client={client}
        clientFields={clientFields}
        subscription={subscription}
        subscriptionFields={subscriptionFields}
        getField={getField}
      />
      <SelectCompanyAdmin
        isEditMode={Boolean(subscription?.id)}
        userRole={userRole}
        userCompany={userCompany}
        changeUserCompanyEvent={changeUserCompanyEvent}
        companies={companies}
      />
      <div className='col-12 pb-4'>
        {subscription.cancelled_status_reason && subscription.status === "cancelled" && (
          <CancelledSubscriptionCard
            cancelledReason={subscription.cancelled_status_reason}
            status={subscription.status}
          />
        )}
      </div>
      <ClientCard client={client} setClient={setClient} />
      <VehicleCard
        mainCompanyName={userCompany.mainCompanyName}
        vehicle={vehicle}
        setVehicle={setVehicle}
        warningMessage={warningMessage}
        isVinRequired={userCompany.isVinRequired}
      />
      <SubscriptionCard
        userRole={userRole}
        subscription={subscription}
        setSubscription={setSubscription}
        userCompany={userCompany}
        companies={companies}
        packages={companyPackages}
        setShowCancelledModal={setShowCancelledModal}
      />
    </div>
  );
};

const getPageTitle = ({
  id,
  loadedSubscription,
}: {
  id: string;
  loadedSubscription: SubscriptionType | null;
}): string => {
  if (!loadedSubscription) {
    return id ? `Edit subscription with id ${id}` : 'Add new subscription';
  } else {
    const loadedClient = loadedSubscription?.client;
    const type = loadedClient?.type;
    const owner = loadedClient?.insurance_for;
    const name = loadedClient?.name;

    if (!loadedClient || !type || !owner) {
      return 'Invalid Subscription';
    }
    if (type === 'PJ') {
      return `Edit subscription [${owner} / ${name}] ${id}`;
    } else if (type === 'PF') {
      return `Edit subscription [${owner}] ${id}`;
    }
    return 'Invalid Subscription';
  }
};

const updateVehicleTemplateFieldsByCompany = ({
  mainCompanyName,
  setVehicleFields,
  isVinRequired,
  isRedNumber,
}: {
  mainCompanyName: string;
  setVehicleFields: (value: React.SetStateAction<FieldType[]>) => void;
  isVinRequired: boolean;
  isRedNumber: boolean | number;
}) => {
  const companyIsKia = mainCompanyName?.toUpperCase() === 'KIA';
  const companyIsVolvo = mainCompanyName?.toUpperCase() === 'VOLVO';
  setVehicleFields((oldVehicleFields: FieldType[]) =>
    oldVehicleFields.map((field) => {
      // FOR VOLVO
      if (field.name === 'model') {
        return {
          ...field,
          type: companyIsVolvo ? 'select' : 'input',
        };
      }
      // FOR KIA
      if (field.name === 'registration_date') {
        return {
          ...field,
          label: companyIsKia ? 'Data livrării' : 'Data primei înmatriculări',
        };
      }
      if (field.name === 'vin') {
        return {
          ...field,
          required: isVinRequired || !!isRedNumber
        }
      }

      return field;
    })
  );
};

const extractUserCompanyInfoFromSubscription = (
  subscription: SubscriptionType,
  userCompanyId: number,
  userCompanyObject: CompanyType,
  userMainCompanyName: string
) => {
  const subscriptionCompany = subscription?.company;
  const stripePayableFromSubscription =
    subscription?.main_company_stripe_payable || subscriptionCompany?.stripe_payable;
  const stripePayableFromUserCompany =
    userCompanyObject?.main_company_stripe_payable || userCompanyObject?.stripe_payable;
  const userCompanyInfo: UserCompanyType = {
    companyId: subscriptionCompany ? subscriptionCompany.id : userCompanyId,
    mainCompanyId: subscriptionCompany
      ? subscriptionCompany.main_company_id
      : userCompanyObject?.main_company_id,
    mainCompanyName: subscription?.main_company_name || userMainCompanyName,
    mainCompanyStripePayable: stripePayableFromSubscription || stripePayableFromUserCompany,
    isVinRequired: userCompanyObject?.is_vin_required || subscriptionCompany?.is_vin_required,
    companyName: userCompanyObject?.name,
  };

  return userCompanyInfo;
};

const AddEditSubscriptionWrapper: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadedSubscription, setLoadedSubscription] = useState<SubscriptionType | null>(null);
  const {
    subscriptionCompanies,
    setSubscriptionCompanies,
    carModels,
    getCarModels,
    setVehicleFields,
  } = useContext<SubscriptionsContextType>(SubscriptionsContext);
  const [packages, setPackages] = useState<PackageType[]>(null);
  const {
    user: { company_id: userCompanyId, company: userCompanyObject, role },
    mainCompanyName: userMainCompanyName,
  } = useContext<UsersContextType>(UserContext);
  const userRole = (role as unknown as UserModelRole).slug;
  const pageTitle: string = useMemo(
    () => getPageTitle({ id, loadedSubscription }),
    [loadedSubscription]
  );

  const userCompanyInfo: UserCompanyType = extractUserCompanyInfoFromSubscription(
    loadedSubscription,
    userCompanyId,
    userCompanyObject,
    userMainCompanyName
  );

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        if (!id) {
          return;
        }
        const subscriptionResponse = await orbitRequest({
          route: `/subscription?id=${id}`,
          method: RequestMethod.GET,
        });
        if (Boolean(subscriptionResponse) && !subscriptionResponse.error) {
          setLoadedSubscription(subscriptionResponse?.subscription);
        } else {
          return toast.error(`There is no subscription with id ${id}`);
        }
      })(),
      (async () => {
        const response = await orbitRequest({
          route: `/package`,
          method: RequestMethod.GET,
        });
        if (Boolean(response) && !response.error) {
          const packageList: PackageType[] = response.packages;
          setPackages(packageList);
        }
      })(),
      (async () => {
        // at the moment only volvo is the only special case
        const companyIsVolvo = userMainCompanyName?.toUpperCase() === 'VOLVO';
        if (
          (!carModels || carModels.length === 0) &&
          (companyIsVolvo ||
            userRole === UserRole.Admin ||
            userRole === UserRole.AdminSubscriptions)
        ) {
          await getCarModels('volvo');
        }

        setVehicleFields((oldVehicleFields: FieldType[]) =>
          oldVehicleFields.map((field) => {
            if (field.name === 'model') {
              const futureField = { ...field };
              if (
                !companyIsVolvo ||
                userRole === UserRole.Admin ||
                userRole === UserRole.AdminSubscriptions
              ) {
                futureField.type = 'input';
              } else {
                futureField.type = 'select';
              }

              return futureField;
            }
            return field;
          })
        );
      })(),
      (async () => {
        if (
          (userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions) &&
          (!subscriptionCompanies || subscriptionCompanies.length === 0)
        ) {
          const result = await getSubscriptionCompaniesRequest();
          if (result?.companies) {
            setSubscriptionCompanies(result.companies);
          }
        }
      })(),
    ]).then(() => {
      setIsLoading(false);
    });

    if (
      !id &&
      !userCompanyId &&
      userRole !== UserRole.Admin &&
      userRole !== UserRole.AdminSubscriptions
    ) {
      toast.warning('Pe acest cont nu exista o companie asignata! Nu se poate crea o subscriptie!');
    }
  }, []);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      {isLoading ? (
        <LoadingCard />
      ) : (
        <AddEditSubscription
          packages={packages}
          loadedSubscription={loadedSubscription}
          userCompanyInfo={userCompanyInfo}
          userRole={userRole}
          companies={subscriptionCompanies}
        />
      )}
    </>
  );
};

export default AddEditSubscriptionWrapper;
