import React from 'react';

const CancelledSubscriptionCard = ({ cancelledReason, status }) => {
  if (!cancelledReason || !status) {
    return null;
  }

  return (
    <div className="card px-5" style={{backgroundColor: '#ffe5e5',borderRadius: '8px',padding: '1rem',color: '#d9534f',}}>
      <h3 style={{ color: '#d9534f' }}>The subscription has been cancelled</h3>
      <div style={{ width: '100%', fontSize: '16px' }}>
        <div className="fieldForm">
          <b>Reason: </b>
        </div>
        <span>{cancelledReason}</span>
      </div>
    </div>
  );
};

export default CancelledSubscriptionCard;