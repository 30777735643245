import axios from 'axios'
import { PUBLIC_URL } from '../constants/api.constants';
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 60s. If the request takes longer than
// that then the request will be aborted.
const easytrackAxios = axios.create({
  timeout: 60000,
})

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const cookie = window.localStorage['cookie'] ? JSON.parse(window.localStorage['cookie']) : null;
  request.headers['X-FLTSRV-Auth-Token'] = 'J7kmgnUMWXRBQC-gKMF4jvyMZV*FopPh4VEDaaB-3';
  request.headers['X-FLTSRV-Cookie-Token'] = cookie;
  return request
}

const responseHandler = (response) => {

  if (response.status === 403) {
    window.localStorage.clear()
    window.location = PUBLIC_URL
  }

  return response
}

const errorHandler = (error) => {

  if (error.response?.status === 403) {
    window.localStorage.clear()
    window.location = PUBLIC_URL
    return
  }
  return Promise.reject(error)
}

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
easytrackAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

easytrackAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

// Step-4: Export the newly created Axios instance to be used in different locations.
export default easytrackAxios
