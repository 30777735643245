import { RequestMethod, orbitRequest } from "../../utils/api.utils"

export const saveNewSubscription = async (payload: any) => orbitRequest({
  route: '/subscription/createClientVehicleSubscription',
  method: RequestMethod.POST,
  payload
})

export const editSubscription = async (payload: any) => orbitRequest({
  route: '/subscription/updateClientVehicleSubscription',
  method: RequestMethod.POST,
  payload
})

export const refundStripeSubscription = (subscriptionId: number) => {
  return orbitRequest({
    route: '/stripe/refund',
    method: RequestMethod.POST,
    payload: { id: subscriptionId }
  })
}

export const importSubscriptionsRequest = (subscriptionsFile: File) => {
  const formData = new FormData()
  formData.append('document', subscriptionsFile)
  return orbitRequest({
    route: '/subscription/insertData',
    method: RequestMethod.POST,
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const downloadImportFileMock = () => {
  return orbitRequest({
    route: '/subscription/generateCsv',
    method: RequestMethod.GET,
    config: { responseType: 'blob' }
  })
}

export const getSubscriptionsImportRules = () => {
  return orbitRequest({
    route: '/subscription/getImportRules',
    method: RequestMethod.GET
  })
}

export const getSubscriptionsByPrompt = async (searchInput: string) => {
  const result: any = await orbitRequest({
    route: '/subscription/getSubscriptionsByVinAndPlateNumber',
    method: RequestMethod.POST,
    payload: { search_input: searchInput }
  })
  return result
}

export const updateSubscriptionRoadIntervention = async (id: number, transportId: number) => {
  const result: any = await orbitRequest({
    route: '/subscription/updateSubscriptionRoadIntervention',
    method: RequestMethod.POST,
    payload: {id, transport_id: transportId}
  })
  return result
}

export const getSubscriptionsByVIN = async (searchInput: string) => {
  const result: any = await orbitRequest({
    route: `/subscription/getCompanySubscriptionsByVin?vin=${searchInput}`,
    method: RequestMethod.GET,
  })
  return result
}

export const getSubscriptionCampaigns = async () => {
  const result: any = await orbitRequest({
    route: '/subscription-campaign',
    method: RequestMethod.GET
  })
  return result
}

export const cloneSubscription = async (
  subscriptionId: number, 
  activatedAt: Date, 
  expirationDate: Date, 
  pricePaid: number, 
  packageId: number
) => {
  return await orbitRequest({
    route: '/subscription/cloneSubscription',
    payload: { subscription_id: subscriptionId, activated_at: activatedAt, expiration_date: expirationDate, price_paid: pricePaid, package_id: packageId },
    method: RequestMethod.POST,
  });
}

