import React, { useCallback, useContext } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { PackageContext } from '../../../../context/packages.context'
import { FlatSubscriptionType, PaymentType } from '../../../../types/subscription.types'
import { ModalType } from './Table'
import { strToBool } from '../../../../utils/string.utils'
import { TooltipButton, TooltipLink } from '../../../../components/table/icon.actions'

const DownloadButton = ({ subscription }: { subscription: FlatSubscriptionType }) => {
  const { previewContract } = useContext<any>(PackageContext)

  return (<TooltipButton
    title='download'
    iconPath={'/Files/Download.svg'}
    onClick={() => previewContract({
      payload: {
        company_id: subscription.company_id, package_id: subscription.package_id, subscription_id: subscription.id
      },
      fileNameData: {
        packageName: subscription.package_name,
        type: "subscription",
        clientName: subscription.client_name,
        subscriptionActivationDate: subscription.activated_at
      }
    })}
  />)
}

const ViewButton = ({ onPressActionForSubscription }: { onPressActionForSubscription: Function }) => {
  return (<TooltipButton
    title='View'
    iconPath={'/General/Search.svg'}
    onClick={() => onPressActionForSubscription(ModalType.View)}
  />)
}

const SendButton = ({ onPressActionForSubscription }: { onPressActionForSubscription: Function }) => {
  return (<TooltipButton
    title='Send'
    iconPath={'/Communication/Send.svg'}
    onClick={() => onPressActionForSubscription(ModalType.SendEmail)}
  />)
}

const EditButton = ({ subscriptionId }: { subscriptionId: number | string }) => {
  return (<TooltipLink
    title='Edit'
    iconPath={'/Communication/Edit.svg'}
    to={`../add-edit-subscription/${subscriptionId}`}
  />)
}

const CloneButton = ({ onPressActionForSubscription }: { onPressActionForSubscription: Function }) => {
  return (
    <TooltipButton
      title="Clone"
      iconPath={'/General/Duplicate.svg'}
      onClick={() => onPressActionForSubscription(ModalType.Clone)}
    />
  )
}


type KnownVerifyType = boolean | number | string | FlatSubscriptionType

const DisplayElementOptional = ({
  conditional,
  verifyCondition = (conditional: boolean | number | string) => Boolean(conditional),
  children
}: {
  conditional: KnownVerifyType
  verifyCondition?: (conditional: KnownVerifyType) => boolean
  children: React.ReactElement
}) => {
  const shouldDisplayButton = verifyCondition(conditional)
  if (!shouldDisplayButton) {
    return null
  }

  return <>{children}</>
}

const shouldDisplayStripePayment = (sub: FlatSubscriptionType) =>
  strToBool(sub?.stripe_payable) && sub.payment_type === PaymentType.STRIPE

const StripePaymentButton = ({ onPressActionForSubscription }: { onPressActionForSubscription: Function }) => {
  return (<TooltipButton
    title='Send payment email'
    iconPath={'/Shopping/Credit-card.svg'}
    onClick={() => onPressActionForSubscription(ModalType.SendStripePaymentEmail)}
  />)
}

const DeleteButton = ({ onPressActionForSubscription }: { onPressActionForSubscription: Function }) => {
  return (<button
    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm m-1'
    onClick={() => onPressActionForSubscription(ModalType.Delete)}
  >
    <KTSVG
      path='/media/icons/duotone/General/Trash.svg'
      className='svg-icon-1'
    />
  </button>)
}

const Actions = ({
  hasAdminAccess,
  subscription,
  onPressActionButton
}: {
  hasAdminAccess: boolean
  subscription: FlatSubscriptionType,
  onPressActionButton: Function
}) => {
  const onPressActionForSubscription = useCallback(
    (modalType: ModalType) => onPressActionButton({ subscription, modalType }), [subscription]
  )

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '115px' }}>
      <DownloadButton subscription={subscription} />
      {(subscription.status === 'deleted')
        ? <ViewButton onPressActionForSubscription={onPressActionForSubscription} />
        : (
          <>
            <SendButton onPressActionForSubscription={onPressActionForSubscription} />
            <EditButton subscriptionId={subscription?.id} />
            {(
              subscription.company_name === '9695' || 
              subscription.company_name?.toLowerCase() === 'autonom assistance'
            ) && (
              <CloneButton onPressActionForSubscription={onPressActionForSubscription} />
            )}
            <DisplayElementOptional
              conditional={subscription}
              verifyCondition={shouldDisplayStripePayment}
            >
              <StripePaymentButton onPressActionForSubscription={onPressActionForSubscription} />
            </DisplayElementOptional>
            <DisplayElementOptional conditional={hasAdminAccess} >
              <DeleteButton onPressActionForSubscription={onPressActionForSubscription} />
            </DisplayElementOptional>
          </>
        )}
    </div>
  )
}

export default Actions